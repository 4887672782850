<template>
  <div class="mb-4">
    <div v-if="mcu">
      <div class="d-flex justify-content-center my-4">
        <div class="text-center">
          <img :src="mcu.biodata.foto" alt="Foto Anda" class="foto-selfie">
          <span class="fs-18 fw-700 d-block mt-3">{{ mcu.biodata.nama_pasien }}</span>
        </div>
      </div>

      <div class="w-100 mb-3 px-0">
        <div class="card-info p-3 d-flex flex-row align-items-center bg-info-50 border-0 color-info-500">
          <img src="@/assets/icon/exclamation-mark.svg" alt="Ikon tanda seru" style="width: 26px; height: 26px;">
          <p class="mb-0 px-2 fs-12 fw-400">Tunjukkan QR Code ini ke resepsionis</p>
        </div>
      </div>

      <div class="card-info border-0 px-0 d-flex flex-column align-items-center mb-4">
        <!-- <img :src="`https://api.qrserver.com/v1/create-qr-code/?color=007A98&data=${mcu.barcode}`" alt="Qr Code"> -->
        <!-- <br> -->
        <div id="barcode-kkp"></div>
        <router-link :to="{name: 'MCUKkp', params: {kode: mcu.id_mcu}}" class="btn btn-primary btn-login w-100 mt-4">
          Lihat KKP
        </router-link>
      </div>

      <div class="px-0 d-flex flex-column pb-5 pb-md-0 mb-4 mb-md-0 layanan-parrent">
        <span class="fs-14 fw-700 color-neutral-900" v-html="mcu.paket.nama_paket" />
        <div v-for="detail in mcu.paket.paket_detail" :key="detail.nama_pemeriksaan" class="layanan">
          <div class="d-flex align-items-center border-none">
            <img class="me-3" src="@/assets/services/covid/vaccine.png" width="42" alt="">
            <span class="fs-14 fw-700 color-neutral-900"> {{ detail.nama_pemeriksaan }} </span>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { onMounted } from 'vue'
import { mcu } from '@/core/mcu'
import { qrcanvas } from 'qrcanvas'

export default {
  setup () {
    onMounted(() => {
      const container = document.getElementById('barcode-kkp')
      container.innerHTML = ''
      const canvas = qrcanvas({
        cellSize: 13,
        data: mcu.value.barcode,
        foreground: [
          { style: '#007A98' }
        ]
      })
      container.appendChild(canvas)
    })

    return {
      mcu
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-login {
  border: none;
  padding: 10px 20px;
}

.card-info {
  border-radius: 12px;
  padding: 0;
  overflow: hidden;
}

.layanan {
  padding: 12px 0px;
  box-shadow: inset 0px -1px 0px rgba(242, 242, 242, 0.8);
}

.layanan-parrent .layanan:last-child {
  box-shadow: none;
}

.foto-selfie {
  border-radius: 100px;
  width: 84px;
  height: 84px;
}
</style>
