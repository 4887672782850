<template>
  <section id="patient-check">
    <Loading v-if="loading.hasil" />
    <Empty v-else-if="hasil.hasil.length === 0" :message="'Saat ini belum ada hasil untuk ditampilkan.'"/>
    <div v-else class="container mt-4 px-2">
      <div class="row">
        <div class="col-12 col-md-12 px-0">
          <div class="card card-patient radius-12 bg-info-500 border-0">
            <div class="card-body d-flex flex-column">
              <div class="doctor-info d-flex align-items-center gap-2 px-2 py-3 bg-white">
                <img src="@/assets/icon/doctor.svg" alt="Dokter" />
                <div class="description d-flex flex-column">
                  <h6 class="fs-12 lh-14 fw-400 mb-1">
                    Dokter Penanggung Jawab
                  </h6>
                  <div>
                    <span class="fs-14 lh-20 fw-700">{{ hasil.biodata.dokterpj }}</span>
                  </div>
                </div>
              </div>
              <div class="patient-info d-flex align-items-center gap-2 py-3">
                <img :src="mcu.biodata.foto" alt="Foto Anda" class="foto-selfie"/>
                <div class="description d-flex flex-column text-white">
                  <h6 class="fs-12 lh-14 fw-400 mb-1">Nama Pasien</h6>
                  <div>
                    <span class="fs-14 lh-20 fw-700">{{ hasil.biodata.nama_pasien }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

         <div
          class="table-container col-12 col-md-12 mt-3"
          style="box-shadow: inset 0px 1px 0px rgba(242, 242, 242, 0.8);">
          <table class="table table-pemeriksaan">
            <thead class="bg-neutral-100 fs-12 lh-20 fw-700 color-neutral-700">
              <tr>
                <th scope="col" class="text-center">Pemeriksaan</th>
                <th scope="col" class="text-center">Hasil</th>
                <th scope="col" class="text-center">Rujukan</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="datum in hasil.hasil"
                :key="datum.nama_pemeriksaan"
                class="item-result fs-12 lh-22 color-neutral-700">
                <td v-if="datum.no" scope="col" class="fw-700 fs-14 color-neutral-900">{{ datum.no }}. {{ datum.nama_pemeriksaan }}</td>
                <td v-else-if="!datum.hasil && !datum.nilai_rujukan" scope="col" class="fw-700 fs-12 color-neutral-900">{{ datum.nama_pemeriksaan }}</td>
                <td v-else scope="col">{{ datum.nama_pemeriksaan }}</td>
                <td scope="col">{{ datum.hasil }}</td>
                <td scope="col">{{ datum.nilai_rujukan }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import Loading from '@/components/utility/Loading'
import Empty from '@/components/utility/Empty'
import { watch, reactive } from 'vue'
import { getHasil, loading, mcu } from '@/core/mcu'
import { showNotif } from '@/core/utility'

export default {
  components: { Loading, Empty },
  props: ['activeMenu'],
  setup (props) {
    const hasil = reactive({
      biodata: {},
      hasil: []
    })

    const prepareGetHasil = async () => {
      try {
        const result = await getHasil({
          id_mcu: mcu.value.id_mcu
        })
        hasil.biodata = result.biodata
        hasil.hasil = result.hasil

        if (!result.status) {
          showNotif({
            status: 'error',
            message: result.message
          })
        }
      } catch (error) {
        console.error('error dari Hasil prepareGetHasil', error)
      }
    }

    watch(() => props.activeMenu, (newVal) => {
      if (newVal === 5) {
        prepareGetHasil()
      }
    })

    return {
      loading,
      hasil,
      mcu
    }
  }
}
</script>

<style lang="scss" scoped>
#patient-check {
  margin-top: 60px;
}

.card-patient {
  .doctor-info {
    border-radius: 11px;
  }

  .icon-wrapper {
    img {
      width: 14px;
      height: 14px;
    }
  }
}

.table-container {
  @media (max-width: 1024px) {
    padding-left: 0;
    padding-right: 0;
  }

  .table-pemeriksaan thead {
    box-shadow: inset 0px 1px 0px rgba(242, 242, 242, 0.8);
  }

  .table-pemeriksaan tbody tr {
    border: hidden;
  }

  .table-pemeriksaan > tbody > .item-result:nth-child(2n+2) > td {
    background-color: #F7FAFC;
  }
}

.radius-12 {
  border-radius: 12px;
}

.foto-selfie {
  border-radius: 100px;
  width: 36px;
  height: 36px;
}
</style>
