<template>
  <div class="mb-4">
    <Loading v-if="loading.mcu" />
    <div v-else-if="mcu">
      <div class="d-flex justify-content-center my-4">
        <div class="text-center">
          <img v-if="!isPhotoTaken" v-show="!isCameraOpen || isLoading" :src="selfieSrc" alt="Foto Anda" class="foto-selfie">
          <video v-show="!isPhotoTaken && isCameraOpen && !isLoading" ref="camera" class="foto-selfie" width="200" height="200" autoplay playsinline></video>
          <canvas v-show="isPhotoTaken" id="photoTaken" ref="canvas" width="200" height="200" class="foto-selfie"></canvas>
        </div>
      </div>

      <div v-if="isCameraOpen && !isLoading" class="bottom-bar text-center">
        <img src="@/assets/icon/camera.svg" alt="Tutup" role="button" @click="takePhoto">
      </div>

      <button v-else class="btn btn-primary btn-login w-100 mt-4" @click="toggleCamera" :disabled="isLoading">
        {{ isLoading ? 'Loading...':isPhotoTaken ? 'Ulangi':'Ambil Foto' }}
      </button>

      <button v-if="isPhotoTaken" class="btn btn-primary btn-login w-100 mt-4" @click="prepareSubmit">
        Selanjutnya
      </button>

    </div>
  </div>
</template>

<script>
import { mcu, loading, submitFoto } from '@/core/mcu'
import { ref, computed, onBeforeUnmount } from 'vue'
import Loading from '@/components/utility/Loading'
import { showNotif, dataURLtoBlob } from '@/core/utility'
import { user, token } from '@/core/auth'

export default {
  components: { Loading },
  setup () {
    const isCameraOpen = ref(false)
    const isPhotoTaken = ref(false)
    const isShotPhoto = ref(false)
    const isLoading = ref(false)
    const camera = ref(null)
    const canvas = ref(null)

    const selfieSrc = computed(() => {
      return mcu.value.biodata.foto || require('@/assets/user-placeholder.png')
    })

    const createCameraElement = () => {
      isLoading.value = true

      const constraints = (window.constraints = {
        audio: false,
        video: { width: 200, height: 200, facingMode: 'user' }
      })

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => {
          isLoading.value = false
          camera.value.srcObject = stream
        })
        .catch(error => {
          isLoading.value = false
          console.log('error akses webcam = ', error)
          alert('Gagal mengakses webcam')
        })
    }

    const stopCameraStream = () => {
      if (camera.value.srcObject) {
        const tracks = camera.value.srcObject.getTracks()

        tracks.forEach(track => {
          track.stop()
        })
      }
    }

    const takePhoto = () => {
      if (!isPhotoTaken.value) {
        isShotPhoto.value = true

        const FLASH_TIMEOUT = 50

        setTimeout(() => {
          isShotPhoto.value = false
        }, FLASH_TIMEOUT)
      }

      isPhotoTaken.value = !isPhotoTaken.value

      const context = canvas.value.getContext('2d')
      context.drawImage(camera.value, 0, 0, 200, 200)

      isCameraOpen.value = false
      isShotPhoto.value = false
      stopCameraStream()
    }

    const toggleCamera = () => {
      if (isCameraOpen.value) {
        isCameraOpen.value = false
        isPhotoTaken.value = false
        isShotPhoto.value = false
        stopCameraStream()
      } else {
        isPhotoTaken.value = false
        isCameraOpen.value = true
        createCameraElement()
      }
    }

    const prepareSubmit = async () => {
      // jadikan form data
      const fd = new FormData()
      const imageSelfie = document.getElementById('photoTaken').toDataURL('image/png')
      fd.append('foto', dataURLtoBlob(imageSelfie), 'selfie.png')
      fd.append('id_mcu', mcu.value.id_mcu)
      fd.append('id', user.value.id)
      fd.append('token_api', token.value)

      // Submit
      try {
        const result = await submitFoto(fd)
        if (result.status) {
          showNotif({
            status: 'success',
            message: 'Foto selfie berhasil disimpan'
          })
        } else {
          showNotif({
            status: 'error',
            message: result.message
          })
        }
      } catch (error) {
        let msg = 'Gagal kirim data'
        if (error.response?.data?.message) {
          msg = error.response.data.message
        }

        showNotif({
          status: 'error',
          message: msg
        })
      }
    }

    onBeforeUnmount(() => {
      stopCameraStream()
    })

    return {
      mcu,
      selfieSrc,
      prepareSubmit,
      loading,
      toggleCamera,
      isPhotoTaken,
      camera,
      isCameraOpen,
      isLoading,
      takePhoto,
      canvas
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-login {
  border: none;
  padding: 10px 20px;
}

.card-info {
  border-radius: 12px;
  padding: 0;
  overflow: hidden;
}

.foto-selfie {
  border-radius: 100px;
  object-fit: cover;
  width: 200px;
  height: 200px;
}

.bottom-bar {
  @media (max-width: 520px) {
    background: unset;
    position: fixed;
    bottom: 5%;
    left: 0;
    width: 100%;
    border: 0;
  }

  img {
    width: 50px;
  }
}
</style>
