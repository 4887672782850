<template>
  <section id="patient-monitoring">
    <Loading v-if="loading.monitoring" />
    <Empty v-else-if="monitoring.detail.length === 0" :message="'Saat ini belum ada monitoring untuk ditampilkan.'"/>
    <div v-else class="container mt-4">
      <div class="row">
        <div class="col-12 col-md-12 p-0">
          <div class="table-responsive">
            <table class="table table-monitoring table-striped">
              <thead
                class="fs-12 lh-22 fw-700 color-neutral-700 bg-neutral-200 text-center"
              >
                <tr v-for="header in monitoring.header" :key="header">
                  <th scope="col" v-for="(value, key) in header" :key="value" class="text-center">
                    <div :style="{width: (key === 'th_namapemeriksaan' ? '130px':'max-content')}" class="mx-auto">{{ value }}</div>
                  </th>
                </tr>
              </thead>
              <tbody class="fs-12 lh-22 color-neutral-700">
                <tr v-for="detail in monitoring.detail" :key="detail">
                  <td v-for="(value, key) in detail" :key="key">
                    <div
                      :class="{ 'text-center': key !== 'th_namapemeriksaan' }"
                      style="word-break: break-word;"
                      :style="{width: (key === 'th_namapemeriksaan' ? '130px':'200px')}"
                    >
                      {{ value }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Loading from '@/components/utility/Loading'
import Empty from '@/components/utility/Empty'
import { watch, reactive } from 'vue'
import { getMonitoring, loading, kodeRekanan, detailMcu } from '@/core/mcu'

export default {
  components: { Loading, Empty },
  props: ['activeMenu'],
  setup (props) {
    const monitoring = reactive({
      header: [],
      detail: []
    })

    const prepareGetMonitoring = async () => {
      try {
        const result = await getMonitoring({
          kode_rekanan: kodeRekanan.value,
          pas_id: detailMcu.value.biodata.pas_id
        })
        monitoring.header = result.header
        monitoring.detail = result.detail
      } catch (error) {
        console.error('error dari Monitoring prepareGetMonitoring', error)
      }
    }

    watch(() => props.activeMenu, (newVal) => {
      if (newVal === 6) {
        prepareGetMonitoring()
      }
    })

    return {
      loading,
      monitoring
    }
  }
}
</script>

<style lang="scss" scoped>
#patient-monitoring {
  margin-top: 60px;
}

.card-patient {
  .doctor-info {
    border-radius: 11px;
  }

  .icon-wrapper {
    img {
      width: 14px;
      height: 14px;
    }
  }
}

.table-responsive {
  overflow-x: auto;

  .table-monitoring {
    th:first-child,
    td:first-child {
      position: sticky;
      left: 0px;
      padding-left: 20px;
    }

    th:first-child {
      background-color: #edf2f7;
    }

    td:first-child {
      background-color: #f7fafc;
    }
  }

  .table-monitoring > :not(caption) > * > * {
    border-bottom-width: 0px !important;
  }
}
</style>
