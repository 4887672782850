<template>
    <div class="mb-4" ref="titlePage">
        <Loading v-if="loading.mcu" />
        <Empty v-else-if="listPertanyaan.length === 0" :message="'Saat ini belum ada pertanyaan untuk ditampilkan.'"/>
        <div v-else class="">
          <div class="w-100 my-3 px-0">
            <div class="card-info p-3 d-flex flex-row align-items-center bg-info-50 border-0 color-info-500">
              <img src="@/assets/icon/exclamation-mark.svg" alt="Ikon tanda seru" style="width: 26px; height: 26px;">
              <p class="mb-0 px-2 fs-12 fw-400">Untuk memudahkan melakukan pemeriksaan isilah sesuai dengan kondisi anda.</p>
            </div>
          </div>

          <span v-if="errorMessage" class="invalid-feedback">{{ errorMessage }}</span>

          <div v-for="pertanyaan in listPertanyaan" :key="pertanyaan.id_pertanyaan" class="px-0 mb-3">
            <span class="fs-18 fw-700 color-neutral-900 mb-3">{{ pertanyaan.pertanyaan ?? 'Covid-19 Status:' }}</span>
            <div v-for="jawaban in pertanyaan.jawaban" :key="jawaban.id_jawaban" class="w-100 my-2 px-0">
              <div v-if="jawaban.format === 'checkbox'"
               class="card-info p-3 d-flex flex-row align-items-center border-0 justify-content-between"
               :class="{'bg-primary-50':jawabanUser[jawaban.name], 'bg-neutral-100':!jawabanUser[jawaban.name]}"
              >
                <label v-if="jawaban.format === 'checkbox'" class="form-check-label fs-12 fw-400 pe-3"
                 :class="{'color-primary-500':jawabanUser[jawaban.name], 'color-neutral-900':!jawabanUser[jawaban.name]}">
                  {{ jawaban.label }}
                </label>

                <input
                  class="cursor-pointer"
                  :type="formatType(jawaban.format)"
                  :class="{
                    'form-check-input':(jawaban.format === 'checkbox'),
                    'form-control':!(jawaban.format === 'checkbox')
                  }"
                  :name="pertanyaan.name"
                  :value="jawaban.label"
                  v-model="jawabanUser[jawaban.name]"
                >
              </div>
            </div>
          </div>

          <!-- Ini TTD -->
          <div class="d-flex justify-content-between align-items-center mb-3">
            <span class="fs-18 fw-700 color-neutral-900">Tanda Tangan</span>
            <span class="btn btn-hapus fs-12 color-neutral-600" @click="$refs.ttdUser.clearSignature()">Hapus</span>
          </div>
          <VueSignaturePad
            :options="{onBegin: () => {$refs.ttdUser.resizeCanvas()}}"
            class="w-100 bg-white mx-auto signature-pad"
            id="ttd"
            height="250px"
            ref="ttdUser"
          />

          <!-- Bagian Submit -->
          <button class="btn btn-primary btn-login w-100 mt-1" data-bs-toggle="modal" data-bs-target="#modalPreview">Selanjutnya</button>

          <!-- Modal -->
          <div class="modal fade" id="modalPreview" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
            <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-body">
                  <div class="d-flex flex-column align-items-center justify-content-center text-center">
                    <div class="d-flex flex-column align-items-center justify-content-center">
                      <span class="fw-700 fs-18 lh-28 color-neutral-900 mt-md-3 mb-1">
                        Preview Jawaban
                      </span>
                    </div>
                  </div>
                  <div class="desc-group mt-3 mt-md-0">
                    <div v-for="pertanyaan in listPertanyaan" :key="pertanyaan.id_pertanyaan">
                      <span class="fs-18 fw-700 color-neutral-900 mb-3">{{ pertanyaan.pertanyaan ?? 'Covid-19 Status:' }}</span>
                      <table class="table table-striped">
                        <tbody>
                          <tr v-for="jawaban in pertanyaan.jawaban" :key="jawaban.id_jawaban">
                            <td class="w-75">{{ jawaban.label }}</td>
                            <td>
                              <span v-if="jawabanUser[jawaban.name] === true">Ya</span>
                              <span v-else-if="jawabanUser[jawaban.name] === false">Tidak</span>
                              <span v-else>{{ jawabanUser[jawaban.name] }}</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="modal-footer d-flex justify-content-between">
                  <button type="button" class="btn btn-danger col py-2" data-bs-dismiss="modal">Batal</button>
                  <button type="button" class="btn btn-primary col py-2" @click="prepareSubmit">Kirim</button>
                </div>
              </div>
            </div>
          </div>

        </div>
    </div>
</template>

<script>
import { Modal } from 'bootstrap'
import Loading from '@/components/utility/Loading'
import Empty from '@/components/utility/Empty'
import { onMounted, ref } from 'vue'
import { getPertanyaan, loading, mcu, submitPertanyaan } from '@/core/mcu'
import { user, token } from '@/core/auth'
import { DataURIToBlob, showNotif } from '@/core/utility'

export default {
  components: { Loading, Empty },
  setup () {
    const listPertanyaan = ref([])
    const jawabanUser = ref({})
    const ttdUser = ref(null)
    const errorMessage = ref('')
    const titlePage = ref(null)

    const formatType = (type) => {
      if (type === 'radiobutton') {
        return 'radio'
      }

      return type
    }

    const updateRadio = (pertanyaan, jawaban) => {
      for (let i = 0; i < listPertanyaan.value.length; i++) {
        if (listPertanyaan.value[i].name === pertanyaan) {
          for (let j = 0; j < listPertanyaan.value[i].jawaban.length; j++) {
            if (listPertanyaan.value[i].jawaban[j].name === jawaban) {
              jawabanUser.value[listPertanyaan.value[i].jawaban[j].name] = true
            } else {
              jawabanUser.value[listPertanyaan.value[i].jawaban[j].name] = false
            }
          }
        }
      }
    }

    const checkRequired = () => {
      let checked = false
      for (let i = 0; i < listPertanyaan.value.length; i++) {
        const elements = document.getElementsByName(listPertanyaan.value[i].name)
        checked = false
        for (let j = 0; j < elements.length; j++) {
          if (elements[j].checked) {
            checked = true
          }
        }

        if (!checked) {
          return false
        }
      }

      return checked
    }

    const prepareSubmit = async () => {
      if (!checkRequired()) {
        const modal = Modal.getOrCreateInstance(document.querySelector('#modalPreview'))
        modal.toggle()
        errorMessage.value = 'Ada pertanyaan yang masih belum dijawab'

        setTimeout(function () {
          titlePage.value.scrollIntoView({ behavior: 'smooth' })
        }, 500)
        return
      }

      // Inject ttd nya
      if (ttdUser.value && !ttdUser.value.isEmpty()) {
        jawabanUser.value.ttd = DataURIToBlob(ttdUser.value.saveSignature('image/png').data)
      } else {
        errorMessage.value = 'Tanda tangan wajib diisi'
        const modal = Modal.getOrCreateInstance(document.querySelector('#modalPreview'))
        modal.toggle()
        return
      }

      // Inject id pendaftar
      jawabanUser.value.id_pendaftar = user.value.id

      // Inject id_mcu
      jawabanUser.value.id_mcu = mcu.value.id_mcu

      // Inject pertanyaan
      jawabanUser.value.pertanyaan = 'deklarasi_sehat'

      // Inject token api
      jawabanUser.value.token_api = token.value

      // jadikan form data
      const fd = new FormData()
      const keys = Object.keys(jawabanUser.value)
      for (let i = 0; i < keys.length; i++) {
        // Cuma kirim yg ada isinya aja
        if (jawabanUser.value[keys[i]] !== false) {
          if (keys[i] === 'ttd') { // kalau ttd ada penanganan khusus
            fd.append(keys[i], jawabanUser.value[keys[i]], 'ttd.png')
          } else {
            fd.append(keys[i], jawabanUser.value[keys[i]])
          }
        }
      }

      // Submit
      try {
        const modal = Modal.getOrCreateInstance(document.querySelector('#modalPreview'))
        modal.toggle()
        const result = await submitPertanyaan(fd)
        if (result.status) {
          showNotif({
            status: 'success',
            message: 'Deklarasi sehat berhasil disimpan'
          })
        } else {
          showNotif({
            status: 'error',
            message: result.message
          })
        }
      } catch (error) {
        let msg = 'Gagal kirim data'
        if (error.response?.data?.message) {
          msg = error.response.data.message
        }

        showNotif({
          status: 'error',
          message: msg
        })
      }
    }

    onMounted(async () => {
      const result = await getPertanyaan({
        id_pendaftar: user.value.id,
        pertanyaan: 'deklarasi_sehat'
      })

      if (window.localStorage.getItem('deklarasi_sehat.' + mcu.value.id_mcu)) {
        jawabanUser.value = JSON.parse(window.localStorage.getItem('deklarasi_sehat.' + mcu.value.id_mcu))
      } else {
        for (let i = 0; i < result.data.length; i++) {
          jawabanUser.value[result.data[i].name] = result.data[i].pertanyaan
          for (let j = 0; j < result.data[i].jawaban.length; j++) {
            let tempJawaban = false
            if (result.data[i].jawaban[j].format === 'text') {
              tempJawaban = ''
            }
            jawabanUser.value[result.data[i].jawaban[j].name] = tempJawaban
          }
        }
        window.localStorage.setItem('deklarasi_sehat.' + mcu.value.id_mcu, JSON.stringify(jawabanUser.value))
      }

      listPertanyaan.value = result.data
    })

    return {
      loading,
      listPertanyaan,
      formatType,
      jawabanUser,
      prepareSubmit,
      updateRadio,
      ttdUser,
      errorMessage,
      titlePage
    }
  },
  watch: {
    jawabanUser: {
      handler (newValue) {
        window.localStorage.setItem('deklarasi_sehat.' + mcu.value.id_mcu, JSON.stringify(newValue))
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-login {
  border: none;
  padding: 10px 20px;
}

.card-info {
  border-radius: 12px;
  padding: 0;
  overflow: hidden;
}

.form-check-input:checked {
  border: none;
}

input.form-control {
  border-radius: 8px;
}

.invalid-feedback {
  font-size: 12px;
}

.signature-pad {
  border: 1px solid #E2E8F0;
  border-radius: 12px;
}

.btn-hapus {
  padding: 8px 20px;
  background: #F7FAFC;
  border-radius: 100px;
  padding: 8px 20px;
}
</style>
