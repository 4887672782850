<template>
  <div>
    <div class="container">
      <app-bar
          :title="tabActive.judul_tab_menu"
          :previousLink="{ name: 'MCU' }" />
      <div class="row">
        <div class="px-3">
          <ul class="nav nav-tabs flex-nowrap overflow-scroll align-items-center" role="tablist">

            <div v-for="(tab_menu, index) in mcu.tab_menu" :key="tab_menu.kode_tab">
              <li class="nav-item d-flex align-items-center" role="presentation">
                <div
                  v-if="index > 0"
                  class="divider-mcu"
                  :class="{'visited':tab_menu.status_isi_data === 1 || tab_menu.urutan <= tabActive.urutan}"
                ></div>
                <img
                  v-if="isTabDisabled(tab_menu.kode_tab)"
                  :src="tab_menu.icon"
                  class="icon-tab"
                  :class="{ 'active': isTabActive(tab_menu) }"
                  :alt="'icon-' + tab_menu.kode_tab"
                  data-bs-toggle="tab"
                  :data-bs-target="'#tab-' + tab_menu.kode_tab"
                  type="button"
                  role="tab"
                  disabled
                >
                <img
                  v-else
                  :src="tab_menu.icon"
                  class="icon-tab"
                  :class="{ 'active': isTabActive(tab_menu) }"
                  :alt="'icon-' + tab_menu.kode_tab"
                  data-bs-toggle="tab"
                  :data-bs-target="'#tab-' + tab_menu.kode_tab"
                  type="button"
                  role="tab"
                  @click="setTabActive(tab_menu)"
                >
              </li>
            </div>
          </ul>
          <div class="tab-content">
            <div
              v-for="tab_menu in mcu.tab_menu"
              :key="tab_menu.status_isi_data + '-' + tab_menu.kode_tab"
              :id="'tab-' + tab_menu.kode_tab"
              class="tab-pane fade"
              :class="{ 'show active': isTabActive(tab_menu) }"
              role="tabpanel"
            >
            <!-- <button class="btn btn-primary" @click="tab_menu.status_isi_data = 1">
              lanjut = {{ tab_menu.status_isi_data + '-' + tab_menu.kode_tab }} = {{ tab_menu.status_isi_data }}
            </button> -->
              <foto
                v-if="tab_menu.jenis_form === 'foto'"
                :kodeTab=tab_menu.kode_tab
              />

              <deklarasi-sehat
                v-if="tab_menu.jenis_form === 'deklarasi_sehat'"
                :kodeTab=tab_menu.kode_tab
              />

              <anamnesa
                v-if="tab_menu.jenis_form === 'anamnesa'"
                :kodeTab=tab_menu.kode_tab
              />

              <sds-survey
                v-if="tab_menu.jenis_form === 'sds_srq'"
                :kodeTab=tab_menu.kode_tab
              />

              <kkp
                v-if="tab_menu.jenis_form === 'kkp'"
                :kodeTab=tab_menu.kode_tab
              />

              <hasil
                v-if="tab_menu.jenis_form === 'hasil'"
                :kodeTab=tab_menu.kode_tab
              />

              <monitoring
                v-if="tab_menu.jenis_form === 'monitoring'"
                :kodeTab=tab_menu.kode_tab
              />

              <persetujuan-fisik
                v-if="tab_menu.jenis_form === 'fisik'"
                :kodeTab=tab_menu.kode_tab
              />
            </div>
            <!-- <pajanan class="tab-pane fade" id="pajanan" role="tabpanel"/> -->
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import AppBar from '@/components/AppBar.vue'
import DeklarasiSehat from '@/components/home/mcu/DeklarasiSehat'
import Anamnesa from '@/components/home/mcu/Anamnesa'
import SdsSurvey from '@/components/home/mcu/SdsSurvey'
import PersetujuanFisik from '@/components/home/mcu/PersetujuanFisik'
import Foto from '@/components/home/mcu/Foto'
// import Pajanan from '@/components/home/mcu/Pajanan'
import Kkp from '@/components/home/mcu/Kkp'
import Hasil from '@/components/home/mcu/Hasil'
import Monitoring from '@/components/home/mcu/Monitoring'
import { title } from '@/core/page'
import { nextTick, onMounted, ref, reactive, watch } from 'vue'
import { mcu } from '@/core/mcu'

export default {
  components: {
    DeklarasiSehat, Kkp, Anamnesa, SdsSurvey, Foto, AppBar, Hasil, Monitoring, PersetujuanFisik
  },
  setup () {
    const tabActive = reactive({
      judul_tab_menu: '',
      kode_tab: '',
      status_isi_data: 0,
      urutan: 0
    })

    const activeMenu = ref(0)
    const unDisabled = [
      'kkp', 'hasil', 'monitoring'
    ]

    const isTabDisabled = (kode_tab) => {
      // cek dulu semua yg required
      for (let i = 0; i < mcu.value.tab_menu.length; i++) {
        if (!unDisabled.includes(mcu.value.tab_menu[i].kode_tab) && mcu.value.tab_menu[i].status_isi_data === 0) {
          return true
        }
      }

      if (unDisabled.includes(kode_tab)) {
        return false
      }

      return true
    }

    const isTabActive = (payload) => {
      if (payload.kode_tab === tabActive.kode_tab) {
        return true
      }
    }

    const setTabActive = (payload) => {
      tabActive.judul_tab_menu = payload.judul_tab_menu
      tabActive.kode_tab = payload.kode_tab
      tabActive.status_isi_data = payload.status_isi_data
      tabActive.urutan = payload.urutan
    }

    onMounted(() => {
      nextTick(() => {
        title.value = 'MCU Instansi'

        for (let i = 0; i < mcu.value.tab_menu.length; i++) {
          if (mcu.value.tab_menu[i].status_isi_data === 0) {
            setTabActive(mcu.value.tab_menu[i])
            break
          }
        }
      })
    })

    watch(() => mcu, (newVal) => {
      console.log('debug newVal = ', newVal.value.tab_menu)
      for (let i = 0; i < newVal.value.tab_menu.length; i++) {
        if (newVal.value.tab_menu[i].status_isi_data === 0) {
          setTabActive(newVal.value.tab_menu[i])
          break
        }
      }
    }, { deep: true })

    return {
      mcu,
      activeMenu,
      tabActive,
      isTabActive,
      setTabActive,
      isTabDisabled
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/_variables';

.divider-mcu {
  position: static;
  width: 43.67px;
  height: 2px;
  left: 48px;
  top: 19px;

  background: #E2E8F0;
  border-radius: 10px;

  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 0px 8px;

  &.visited {
    background: #007A98;
  }
}

.nav-link {
  border: none;
  border-radius: 8px;
  white-space: nowrap;
  padding: 3px 20px;
  background: #FFF0D4;
  color: #FF9D0A;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  background: $primary;
  color: white;
}

.nav-tabs {
  box-shadow: inset 0px -1px 0px rgba(242, 242, 242, 0.8);
}

.icon-tab {
  width: 40px;
  height: 40px;
}
</style>
